import * as React from 'react';

export function format_whole_number(value?: string | number, fallback = '-', zero = true) {
  let num = value;

  if (typeof value === 'string') {
    num = parseFloat(value);
  }

  if (typeof num !== 'number' || (!zero && num === 0)) {
    return fallback;
  }

  return new Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(num);
}

export function format_money(value?: string | number, fallback = '-', zero = true) {
  let num = value;

  if (typeof value === 'string') {
    num = parseFloat(value);
  }

  if (typeof num !== 'number' || (!zero && num === 0)) {
    return fallback;
  }

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(num);
}

export function useMoney() {
  return format_money;
}

export function Money({
  children,
  none = '-',
  zero = true,
}: {
  children?: number | string;
  none?: React.ReactNode;
  zero?: boolean;
}) {
  return <>{useMoney()(children, '', zero) || none}</>;
}
